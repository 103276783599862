import React, { useState } from "react";
import { QrReader } from "react-qr-scanner";

function AddPost() {
  const [data, setData] = useState("No result");
  const [error, setError] = useState(null);

  return (
    <div className="inner">
      <h2>QR Code Scanner</h2>
      <div style={{ textAlign: "center", width: "300px", height: "300px" }}>
        <QrReader
          delay={300}
          style={{ width: "100%" }}
          onResult={(result, error) => {
            if (result) {
              setData(result?.text);
            }
            if (error) {
              setError(error?.message);
            }
          }}
        />
        <p>Scanned Data: {data}</p>
        {error && <p style={{ color: "red" }}>Error: {error}</p>}
      </div>
    </div>
  );
}

export default AddPost;
