import React from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

// css
import "./App.css";
import "./assets/css/reset.css";
import "./assets/css/root.css";

// components
import Landing from "./pages/landing/index.jsx";
import Nav from "./components/bar/nav.jsx";
import Footer from "./components/footer/index.jsx";
import Estimate from "./pages/estimate/index.jsx";
import Redirector from "./components/function/redirector.jsx";
import Mentions from "./pages/legal/mentions.jsx";
import Cookies from "./pages/legal/cookies.jsx";
import AddPost from "./pages/test/AddPost.jsx";

function App() {
  const router = createBrowserRouter([
    {
      // errorElement: <ErrorPage />,
      path: "/",
      element: [
        <Nav key="nav" />,
        <Outlet key="outlet" />,
        <Footer key="footer" />,
      ],
      children: [
        {
          path: "",
          element: <Landing />,
        },
        {
          path: "/estimate",
          element: <Estimate />,
        },
        {
          path: "/legal/mentions",
          element: <Mentions />,
        },
        {
          path: "/legal/cookies",
          element: <Cookies />,
        },
        {
          path: "/test",
          element: <AddPost />,
        },
      ],
    },
    {
      path: "/url",
      children: [
        {
          path: ":code",
          element: <Redirector />,
        },
      ],
    },
  ]);

  // useEffect(() => {
  //   try {
  //     Logs({
  //       title: "Visited",
  //       text: "App component loaded",
  //       type: "info",
  //       data: "",
  //     });
  //   } catch (e) {
  //     console.log("error" + e);
  //   }
  // });

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
