import React, { useEffect, useRef } from "react";
import "./landing.css";
import { useNavigate } from "react-router-dom";

import { PiEyeFill } from "react-icons/pi";
import { FaMoneyBillWave, FaUserAlt } from "react-icons/fa";
import { LuArrowUpRight } from "react-icons/lu";

import ImageBottomHeader from "../../assets/images/landing/header.svg";

import ImageIphone from "../../assets/images/landing/uix.png";
import ImageSpectrum from "../../assets/images/landing/web.png";
import ButtonComponent from "../../components/button";
import { ContactForm, HeaderContainer, ProjectContainer } from "./components";
import { formatTime } from "../../components/function";

function Landing() {
  const navigate = useNavigate();

  const countupRef = useRef(null);
  const countupRefMoney = useRef(null);
  const countupRefUser = useRef(null);
  let countUpAnim;
  let countUpAnimMoney;
  let countUpAnimUser;

  // useEffect with empty dependency array runs once when component is mounted
  useEffect(() => {
    initCountUp();
  });

  // dynamically import and initialize countUp, sets value of `countUpAnim`
  // you don't have to import this way, but this works best for next.js
  async function initCountUp() {
    setTimeout(async () => {
      const countUpModule = await import("countup.js");
      countUpAnim = new countUpModule.CountUp(countupRef.current, 235645);
      if (!countUpAnim.error) {
        countUpAnim.start();
      } else {
        console.error(countUpAnim.error);
      }
    }, 900);
    setTimeout(async () => {
      const countUpModule = await import("countup.js");
      countUpAnimMoney = new countUpModule.CountUp(
        countupRefMoney.current,
        35924
      );
      if (!countUpAnimMoney.error) {
        countUpAnimMoney.start();
      } else {
        console.error(countUpAnimMoney.error);
      }
    }, 1100);
    setTimeout(async () => {
      const countUpModule = await import("countup.js");
      countUpAnimUser = new countUpModule.CountUp(countupRefUser.current, 135);
      if (!countUpAnimUser.error) {
        countUpAnimUser.start();
      } else {
        console.error(countUpAnimUser.error);
      }
    }, 1300);
  }

  const projectData = [
    {
      name: "Mon Portfolio",
      path: "https://portfolio.loukaaa.com",
    },
    {
      name: "L'Écrin Sauvage",
      path: "https://lecrin-sauvage.fr/",
    },
    {
      name: "DD04Photos",
      path: "https://dd04photos.fr/photos",
    },
    {
      name: "Aksoa",
      path: "https://aksoa.fr",
    },
    {
      name: ".laujourdhui",
      path: "https://portfolio.loukaaa.com/article/laujourdhui/",
    },
    {
      name: "Cloudaaa",
      path: "https://portfolio.loukaaa.com/article/cloudaa/",
    },
  ];

  return (
    <div className="Landing">
      <header id="home">
        <div className="inner">
          <div className="ctn-text">
            <h1>
              <span>
                <div>Votre</div>
              </span>
              <span>
                <div className="second">Aventure</div>
              </span>
              <span>
                <div className="last">Eternelle</div>
              </span>
            </h1>
            <p>
              Découvrez l'excellence du design web sur mesure, conçu
              spécialement pour vous.
            </p>
            <ButtonComponent
              text="Réaliser un devis"
              iconLeft={true}
              skin="white"
              onClick={() => navigate("/estimate")}
            />
          </div>
          <div className="ctn-img">
            <img
              src="https://cdn.sessile.fr/wp-content/webp-express/webp-images/doc-root/wp-content/uploads/2024/04/sessile-copie-de-img-9014-1-1-scaled.jpg.webp"
              alt=""
            ></img>
            <div className="container">
              <div>
                <FaMoneyBillWave className="icon" />
                <span ref={countupRefUser}>0 </span>%
              </div>
              <LuArrowUpRight className="icon arrow" />
            </div>
            <div className="container">
              <div>
                <PiEyeFill className="icon" />
                <span ref={countupRef}>0</span>
              </div>
              <LuArrowUpRight className="icon arrow" />
            </div>
            <div className="container">
              <div>
                <FaUserAlt className="icon" />
                <span ref={countupRefMoney}>0</span>
              </div>
              <LuArrowUpRight className="icon arrow" />
            </div>
          </div>
        </div>
        <img src={ImageBottomHeader} alt="" />
      </header>
      <main>
        <div className="about inner">
          <div className="ctn-text">
            <h2>Améliorer votre visibilité</h2>
            <p>
              Optimisez votre présence en ligne avec notre agence de création
              web et branding.
            </p>
            <div className="ctn-button">
              <ButtonComponent
                text="Commencer"
                onClick={() => navigate("/estimate")}
              />
            </div>
          </div>
        </div>
        <div className="quote inner">
          <h3>
            Votre <span>vision</span>, notre <span>expertise</span>, un{" "}
            <span>site web</span> à votre mesure.
          </h3>
        </div>
        <div id="features" className="features inner">
          <div className="wrapper">
            <div className="container">
              <div className="p-40">
                <h3>Développement Web</h3>
                <p>
                  Expert en développement web, maîtrisant divers langages et
                  technologies, nous proposons des solutions performantes et
                  adaptées.
                </p>
                <ButtonComponent
                  text="Commencer"
                  onClick={() => navigate("/estimate")}
                />
              </div>
              <img src={ImageSpectrum} alt="" />
            </div>
            <div className="flex">
              <div className="container p-40">
                <div>
                  <h4>Designer UX/UI</h4>
                  <p className="text-sm">
                    Spécialisé dans la création d'expériences utilisateurs
                    intuitives et esthétiquement agréables.
                  </p>
                </div>
                <div className="ctn-image">
                  <img src={ImageIphone} alt="" />
                </div>
              </div>
              <div className="container p-40">
                <div>
                  <h4>Hébergement</h4>
                  <p className="text-sm c-white">
                    Nous sommes également compétents en hébergement, maintenance
                    et référencement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="projects" className="project inner">
          <HeaderContainer
            title="Nos Réalisations"
            text="Découvrez nos dernières collaborations et réalisations avec nos clients."
          />
          <ProjectContainer data={projectData} />
        </div>
        <div id="contact" className="contact inner">
          <div className="ctn-img">
            <img
              src="https://images.unsplash.com/photo-1535957998253-26ae1ef29506?q=80&w=2672&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
          </div>
          <ContactForm />
        </div>
      </main>
    </div>
  );
}

export default Landing;
